import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";

interface NavbarProps {
  user: any;
}

const Navbar: React.FC<NavbarProps> = ({ user }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleScrollToFAQ = () => {
    if (location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        const faqSection = document.getElementById("faq");
        if (faqSection) {
          faqSection.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    } else {
      const faqSection = document.getElementById("faq");
      if (faqSection) {
        faqSection.scrollIntoView({ behavior: "smooth" });
      }
    }
    setIsMenuOpen(false);
  };

  const handleContactClick = () => {
    window.open("mailto:19bmiles@gmail.com", "_blank");
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <nav className="bg-primary-black text-accent-green p-4 flex justify-between items-center fixed w-full z-10 border-b border-accent-green">
      <Link to="/" className="text-2xl font-bold mr-4 glow-green-sm">
        HackAI_
      </Link>

      {/* Desktop Menu */}
      <div className="hidden md:flex items-center space-x-4">
        <button
          onClick={handleScrollToFAQ}
          className="hover:text-secondary-green glow-green-sm px-1 m-1"
        >
          FAQ
        </button>
        <Link
          to="/schedule"
          className="hover:text-secondary-green glow-green-sm px-1 m-1"
        >
          Schedule
        </Link>
        <Link
          to="/events"
          className="hover:text-secondary-green glow-green-sm px-1 m-1"
        >
          Events
        </Link>
        <Link
          to="/resources"
          className="hover:text-secondary-green glow-green-sm px-1 m-1"
        >
          Resources
        </Link>
        {user && (
          <Link
            to="/projects"
            className="hover:text-secondary-green glow-green-sm px-1 m-1"
          >
            View_Projects
          </Link>
        )}
        {/* {user ? (
          <button
            onClick={handleLogout}
            className="px-5 py-2 rounded font-bold bg-accent-green text-primary-black hover:bg-secondary-green transition duration-300"
          >
            Logout
          </button>
        ) : (
          <Link
            to="/login"
            className="px-5 py-2 rounded font-bold bg-accent-green text-primary-black hover:bg-secondary-green transition duration-300"
          >
            Login
          </Link>
        )} */}
      </div>

      {/* Mobile Menu Button */}
      <div className="md:hidden flex items-center">
        <button
          onClick={toggleMenu}
          className="p-2 rounded-md text-accent-green hover:text-secondary-green focus:outline-none focus:ring-2 focus:ring-inset focus:ring-accent-green"
        >
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </button>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="md:hidden absolute top-full left-0 right-0 bg-secondary-black border-t border-accent-green">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <button
              onClick={handleScrollToFAQ}
              className="block w-full text-left px-3 py-2 rounded-md text-base font-medium hover:bg-primary-black hover:text-secondary-green"
            >
              FAQ
            </button>
            <Link
              to="/schedule"
              className="block px-3 py-2 rounded-md text-base font-medium hover:bg-primary-black hover:text-secondary-green"
              onClick={() => setIsMenuOpen(false)}
            >
              Schedule
            </Link>
            <Link
              to="/events"
              className="block px-3 py-2 rounded-md text-base font-medium hover:bg-primary-black hover:text-secondary-green"
              onClick={() => setIsMenuOpen(false)}
            >
              Events
            </Link>
            <button
              onClick={handleContactClick}
              className="block w-full text-left px-3 py-2 rounded-md text-base font-medium bg-accent-green text-primary-black hover:bg-secondary-green"
            >
              Contact_Us
            </button>
            <Link
              to="/resources"
              className="block px-3 py-2 rounded-md text-base font-medium hover:bg-primary-black hover:text-secondary-green"
              onClick={() => setIsMenuOpen(false)}
            >
              Resources
            </Link>
            {user && (
              <Link
                to="/projects"
                className="block px-3 py-2 rounded-md text-base font-medium hover:bg-primary-black hover:text-secondary-green"
                onClick={() => setIsMenuOpen(false)}
              >
                View_Projects
              </Link>
            )}
            {user ? (
              <button
                onClick={() => {
                  handleLogout();
                  setIsMenuOpen(false);
                }}
                className="block w-full text-left px-3 py-2 rounded-md text-base font-medium bg-accent-green text-primary-black hover:bg-secondary-green"
              >
                Logout
              </button>
            ) : (
              <Link
                to="/login"
                className="block px-3 py-2 rounded-md text-base font-medium bg-accent-green text-primary-black hover:bg-secondary-green"
                onClick={() => setIsMenuOpen(false)}
              >
                Login
              </Link>
            )}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
