import React from "react";
import { FaClock, FaLaptopCode, FaTrophy, FaPizzaSlice } from "react-icons/fa";

const Schedule: React.FC = () => {
  const scheduleItems = [
    {
      time: "6:00pm - 6:15pm",
      event: "Intro to HackAI",
      icon: <FaClock />,
    },
    {
      time: "6:15pm - 6:30pm",
      event: "Presentation 1",
      icon: <FaTrophy />,
    },
    {
      time: "6:30pm - 6:45pm",
      event: "Presentation 2",
      icon: <FaTrophy />,
    },
    { time: "6:45pm - 8:00pm", event: "Hack", icon: <FaLaptopCode /> },
  ];

  return (
    <div className="bg-primary-black text-accent-green min-h-screen p-8 font-mono">
      <div className="max-w-4xl mx-auto mt-20">
        <h1 className="text-4xl font-bold mb-8 text-center glow-green-sm">
          HackAI_Schedule
        </h1>

        <div className="bg-secondary-black rounded-lg shadow-md p-6 mb-8 border border-accent-green">
          <h2 className="text-2xl font-semibold mb-4 text-accent-green">
            $ date
          </h2>
          <p className="text-secondary-green mb-4">
            February 6th, 6:00pm-8:00pm
          </p>

          <h3 className="text-xl font-semibold mb-2 text-accent-green">
            $ cat agenda.txt
          </h3>
          <ul className="space-y-4">
            {scheduleItems.map((item, index) => (
              <li
                key={index}
                className="flex items-center text-secondary-green"
              >
                <span className="mr-2 text-accent-green">
                  {React.cloneElement(item.icon, { size: 20 })}
                </span>
                <span className="font-medium mr-2 text-accent-green">
                  {item.time}:
                </span>
                <span>{item.event}</span>
              </li>
            ))}
          </ul>
        </div>

        <div className="bg-secondary-black rounded-lg shadow-md p-6 border border-accent-green">
          <h3 className="text-xl font-semibold mb-4 flex items-center text-accent-green">
            <FaPizzaSlice size={24} className="mr-2" />$ ls refreshments/
          </h3>
          <p className="text-secondary-green">
            Pizza.txt Beer.exe Soda.bin Water.sys
          </p>
        </div>
      </div>
    </div>
  );
};

export default Schedule;
