import React from "react";
import { FaMicrophone, FaProjectDiagram } from "react-icons/fa";

const UpcomingEvents: React.FC = () => {
  const upcomingEvents = [
    // {
    //   id: "1",
    //   title: "AI Open-Hackathon",
    //   date: "November 7th, 2024",
    //   theme: "Voice AI",
    //   project: "Building a voice AI assistant like Jarvis",
    //   sponsor: {
    //     name: "TBD",
    //     website: "",
    //     twitter: "",
    //   },
    // },
    {
      id: "2",
      title: "AI Tool Demos & Open Hack Session",
      date: "February 6th, 2025",
      theme: "AI Tools",
      project: "Building AI tools with ToolHouse & Building an AI Agent",
      sponsor: {
        name: "ToolHouse",
        website: "https://toolhouse.ai/",
        twitter: "https://x.com/toolhouseai",
      },
    },
  ];

  return (
    <section className="mb-16">
      <h2 className="text-3xl font-semibold text-accent-green mb-6">
        $ ls upcoming_events/
      </h2>
      <div className="grid grid-cols-1 gap-6">
        {upcomingEvents.map((event) => (
          <div
            key={event.id}
            className="bg-secondary-black rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition duration-300 ease-in-out transform hover:-translate-y-1 border border-accent-green"
          >
            <div className="p-6">
              <h3 className="text-2xl font-bold text-accent-green mb-2">
                {event.title}
              </h3>
              <p className="text-secondary-green mb-4">{event.date}</p>
              <div className="flex items-center text-accent-green mb-2">
                <FaMicrophone className="mr-2" />
                <span className="font-semibold">Theme:</span> {event.theme}
              </div>
              <div className="flex items-center text-accent-green mb-4">
                <FaProjectDiagram className="mr-2" />
                <span className="font-semibold">Project:</span> {event.project}
              </div>
              <p className="text-secondary-green mb-4">
                <span className="font-semibold text-accent-green">
                  Sponsor:
                </span>{" "}
                {event.sponsor.name}
              </p>
              <a
                href="https://lu.ma/g0zdjkti"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-accent-green text-primary-black font-bold py-2 px-4 rounded hover:bg-secondary-green transition duration-300 ease-in-out"
              >
                $ rsvp --event="AI Tool Demos & Open Hack Session"
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default UpcomingEvents;
