import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import FAQ from "./components/FAQ";
import Schedule from "./components/Schedule";
import SubmitProject from "./components/SubmitProject";
import ProjectList from "./components/ProjectList";
import EmailList from "./components/EmailList";
import UnderConstruction from "./components/UnderConstruction";
import Footer from "./components/Footer";
import QuickLinks from "./components/QuickLinks";
import { Analytics } from "@vercel/analytics/react";
import EventsPage from "./components/EventsPage";
import ResourcesPage from "./components/Resources";

const App: React.FC = () => {
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const isAuthorized =
    user &&
    (user.email === "reid@linkt.ai" || user.email === "19bmiles@gmail.com");

  if (loading) {
    return <div className="text-accent-green">Loading...</div>;
  }

  return (
    <Router>
      <div className="App flex flex-col min-h-screen bg-primary-black text-accent-green font-mono">
        <Navbar user={user} />
        <main className="flex-grow pt-16 md:pt-20">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Hero />
                  <QuickLinks />
                  <FAQ />
                </>
              }
            />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/events" element={<EventsPage />} />
            <Route path="/submit" element={<SubmitProject />} />
            {/* <Route
              path="/login"
              element={!user ? <Login /> : <Navigate to="/projects" />}
            /> */}
            <Route
              path="/projects"
              element={isAuthorized ? <ProjectList /> : <UnderConstruction />}
            />
            <Route
              path="/emails"
              element={isAuthorized ? <EmailList /> : <UnderConstruction />}
            />
            <Route path="/resources" element={<ResourcesPage />} />
          </Routes>
        </main>
        <Footer />
      </div>
      <Analytics />
    </Router>
  );
};

export default App;
