import React, { useState } from "react";
import { FaChevronDown, FaChevronUp, FaSearch, FaFilter } from "react-icons/fa";

interface Resource {
  title: string;
  description: string;
  difficulty?: "Beginner" | "Intermediate" | "Advanced";
  type: "Documentation" | "Tutorial" | "Tool" | "Other";
  url: string;
}

interface MeetupResources {
  date: string;
  title: string;
  resources: Resource[];
}

const generalResources: Resource[] = [
  {
    title: "AI Prompting Guide",
    description: "A comprehensive guide on effective prompting for AI models",
    type: "Documentation",
    url: "https://example.com/ai-prompting-guide",
  },
  // Add more general resources here
];

const meetupResources: MeetupResources[] = [
  {
    date: "December 5, 2024",
    title: "Building Web Apps with Cursor IDE",
    resources: [
      {
        title: "Cursor Agent System Presentation",
        description:
          "PowerPoint presentation covering how to build web applications using Cursor IDE's new agent feature",
        type: "Documentation",
        difficulty: "Intermediate",
        url: "https://docs.google.com/presentation/d/1q00Gz6i6jAaV4gxXAd5PzyX1Xbw8hIcax-BIsLvmSmM/edit?usp=sharing",
      },
    ],
  },
  {
    date: "September 5, 2024",
    title: "Voice AI Projects with Sindarin",
    resources: [
      {
        title: "Create a persona that you can call and have a convo with",
        description:
          "Guide to create various AI personas. Then to deploy them to be callable through Twilio.",
        type: "Tutorial",
        difficulty: "Beginner",
        url: "https://github.com/19bmiles/hackai-sindarin-templates",
      },
      {
        title: "Advanced Sindarin Burgers Tutorial",
        description:
          "Guide to extend functionality of a basic burger restaurant cashier AI persona",
        type: "Tutorial",
        difficulty: "Advanced",
        url: "https://github.com/19bmiles/sindarin-burgers-hackai",
      },
      {
        title: "Sindarin Platform",
        description: "Platform for creating voice AI personas",
        type: "Tool",
        url: "https://sindarin.com",
      },
      {
        title: "Twilio Documentation",
        description: "Documentation for creating phone numbers with Twilio",
        type: "Documentation",
        url: "https://www.twilio.com/docs",
      },
      // Add more resources for this meetup
    ],
  },
  {
    date: "August 1, 2024",
    title: "Fine-tuning LLaMA 3.1 with Brev",
    resources: [
      {
        title: "Brev Platform",
        description: "Platform for fine-tuning LLaMA 3.1",
        type: "Tool",
        url: "https://brev.dev",
      },
      // Add the second link for this meetup
    ],
  },
];

const ResourceItem: React.FC<Resource> = ({
  title,
  description,
  difficulty,
  type,
  url,
}) => (
  <div className="py-3">
    <h3 className="text-lg font-semibold">
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="text-accent-green hover:text-secondary-green"
      >
        {title}
      </a>
    </h3>
    <p className="text-highlight mt-1">{description}</p>
    <div className="flex mt-2 space-x-2">
      <span className="px-2 py-1 bg-secondary-black text-accent-green text-sm rounded">
        {type}
      </span>
      {difficulty && (
        <span className="px-2 py-1 bg-secondary-black text-secondary-green text-sm rounded">
          {difficulty}
        </span>
      )}
    </div>
  </div>
);

const MeetupSection: React.FC<MeetupResources> = ({
  date,
  title,
  resources,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="mb-6 bg-secondary-black rounded-lg overflow-hidden">
      <div
        className="flex justify-between items-center cursor-pointer p-4 bg-primary-black border-l-4 border-accent-green"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h2 className="text-xl font-bold text-accent-green">
          {date} - {title}
        </h2>
        {isOpen ? (
          <FaChevronUp size={20} className="text-accent-green" />
        ) : (
          <FaChevronDown size={20} className="text-accent-green" />
        )}
      </div>
      {isOpen && (
        <div className="p-4 space-y-4">
          {resources.map((resource, index) => (
            <ResourceItem key={index} {...resource} />
          ))}
        </div>
      )}
    </div>
  );
};

const ResourcesPage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedEvent, setSelectedEvent] = useState<string | null>(null);

  const filteredMeetups = meetupResources
    .filter((meetup) => !selectedEvent || meetup.title === selectedEvent)
    .map((meetup) => ({
      ...meetup,
      resources: meetup.resources.filter(
        (resource) =>
          resource.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          resource.description.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    }))
    .filter((meetup) => meetup.resources.length > 0);

  const filteredGeneralResources = generalResources.filter(
    (resource) =>
      resource.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      resource.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container mx-auto px-4 py-8 bg-primary-black text-highlight">
      <h1 className="text-3xl font-bold mb-4 text-accent-green">
        HackAI Resources
      </h1>
      <p className="mb-6">
        Welcome to the HackAI resources page! Here you'll find resources
        corresponding to each of our meetup events, as well as general AI
        resources. Use these materials to enhance your learning and projects.
      </p>
      <div className="mb-6 flex space-x-4">
        <div className="relative flex-grow">
          <input
            type="text"
            placeholder="Search resources..."
            className="w-full px-4 py-2 bg-secondary-black border border-accent-green rounded-md pr-10 text-highlight placeholder-secondary-green focus:outline-none focus:ring-2 focus:ring-accent-green focus:border-transparent"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <FaSearch
            className="absolute right-3 top-3 text-secondary-green"
            size={16}
          />
        </div>
        <div className="relative">
          <select
            className="appearance-none bg-secondary-black border border-accent-green rounded-md px-4 py-2 pr-8 text-highlight focus:outline-none focus:ring-2 focus:ring-accent-green focus:border-transparent"
            value={selectedEvent || ""}
            onChange={(e) => setSelectedEvent(e.target.value || null)}
          >
            <option value="">All Events</option>
            {meetupResources.map((meetup, index) => (
              <option key={index} value={meetup.title}>
                {meetup.title}
              </option>
            ))}
          </select>
          <FaFilter
            className="absolute right-3 top-3 text-secondary-green pointer-events-none"
            size={16}
          />
        </div>
      </div>
      <div className="space-y-6">
        {filteredMeetups.map((meetup, index) => (
          <MeetupSection key={index} {...meetup} />
        ))}
        {filteredGeneralResources.length > 0 && (
          <div className="bg-secondary-black rounded-lg overflow-hidden">
            <h2 className="text-2xl font-bold p-4 bg-primary-black border-l-4 border-accent-green text-accent-green">
              General AI Resources
            </h2>
            <div className="p-4 space-y-4">
              {filteredGeneralResources.map((resource, index) => (
                <ResourceItem key={index} {...resource} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResourcesPage;
